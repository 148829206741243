import { Input as NextUIInput } from "@nextui-org/input";
import { InputProps as NextUIInputProps } from "@nextui-org/react";

type Props = Pick<
  NextUIInputProps,
  "className" | "label" | "type" | "placeholder" | "name"
>;

export function Input({ className, label, placeholder, type, name }: Props) {
  return (
    <NextUIInput
      variant="bordered"
      size="lg"
      type={type}
      label={label}
      placeholder={placeholder}
      className={className}
      name={name}
    />
  );
}
