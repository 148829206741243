import { Link } from "react-router-dom";
import { UploadReceipt } from "./UploadReceipt";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export function Header({
  showUploadReceipt,
  showSearchByBarcode,
}: {
  showUploadReceipt: boolean;
  showSearchByBarcode: boolean;
}) {
  return (
    <div className="pb-4 flex justify-between">
      {/* {showSearchByBarcode ? (
        <span>
          <Link to="/search-by-barcode">
            <MagnifyingGlassIcon className="size-6" />
          </Link>
        </span>
      ) : null} */}
      <span className="text-lg font-bold">
        <Link to={showUploadReceipt ? "/home" : "/"}>recibo</Link>
      </span>
      {showUploadReceipt ? <UploadReceipt /> : null}
    </div>
  );
}
