import { Form, useLoaderData } from "react-router-dom";
import { getApiHost } from "../getApiHost";
import { LineItemByBarcodeRow } from "./LineItemByBarcodeRow";
import { Button } from "../atoms/Button";

export type LineItemByBarcodeResponse = {
  line_item_id: string;
  line_item_name: string;
  vendor_name: string;
  purchased_at: string;
  quantity: number;
  unit_price_in_cents: number;
  barcode: string;
};

export type LineItemByBarcodeData = {
  lineItemId: string;
  lineItemName: string;
  vendorName: string;
  purchasedAt: string;
  quantity: number;
  unitPriceInCents: number;
  barcode: string;
};

export const loader =
  (token: string | undefined) =>
  async ({ params, request }: { params: any; request: any }) => {
    const response = await fetch(`${getApiHost()}/line_item_by_barcode`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        barcode: decodeURIComponent(params.barcode),
      }),
    });
    if (response.status === 200) {
      const data: LineItemByBarcodeResponse[] = await response.json();
      const lineItems = data.map((lineItem) => ({
        lineItemId: lineItem.line_item_id,
        lineItemName: lineItem.line_item_name,
        vendorName: lineItem.vendor_name,
        purchasedAt: lineItem.purchased_at,
        quantity: lineItem.quantity,
        unitPriceInCents: lineItem.unit_price_in_cents,
        barcode: lineItem.barcode,
      }));
      return { token, lineItems };
    }
    return {
      token,
      lineItems: [],
    };
  };

export const action =
  (token: string | undefined) =>
  async ({ params, request }: { params: any; request: any }) => {
    const formData = await request.formData();
    const intent = formData.get("intent");
    if (intent === "DELETE_BARCODE_BY_LINE_ITEM_NAME") {
      const lineItemName = formData.get("lineItemName") as string;
      try {
        const response = await fetch(
          `${getApiHost()}/delete_barcode_by_line_item_name`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              lineItemName,
            }),
          }
        );
        if (response.status === 200) {
          console.log("Successfully removed barcode");
        } else {
          console.error("Failed to remove barcode");
        }
        return null;
      } catch (e) {
        console.error(e);
        return null;
      }
    }
    if (intent === "DELETE_BARCODE_BY_LINE_ITEM_ID") {
      const lineItemId = formData.get("lineItemId") as string;
      try {
        const response = await fetch(
          `${getApiHost()}/delete_barcode_by_line_item_id`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              lineItemId,
            }),
          }
        );
        if (response.status === 200) {
          console.log("Successfully removed barcode");
        } else {
          console.error("Failed to remove barcode");
        }
        return null;
      } catch (e) {
        console.error(e);
        return null;
      }
    }
    alert("Something went wrong. Please try again later");
    return null;
  };

export function LineItemByBarcode() {
  const { token, lineItems } = useLoaderData() as {
    token: string;
    lineItems: LineItemByBarcodeData[];
  };

  return (
    <div className="h-full">
      <div className="text-lg flex justify-between items-center gap-2 px-3">
        <span>{lineItems[0]?.lineItemName}</span>
        <span className="inline-block">
          <Form method="post" className="inline">
            <input
              type="hidden"
              name="lineItemName"
              value={lineItems[0]?.lineItemName}
            />
            <Button
              type="submit"
              name="intent"
              value="DELETE_BARCODE_BY_LINE_ITEM_NAME"
              color={"default"}
              size={"sm"}
              text={"Remove barcode from all"}
            />
          </Form>
        </span>
      </div>
      {lineItems.map((lineItem, index) => (
        <LineItemByBarcodeRow key={index} lineItem={lineItem} token={token} />
      ))}
    </div>
  );
}
