import { Link } from "@nextui-org/react";

export function LegalFooter() {
  return (
    <div className="flex place-content-evenly">
      <Link href="/privacy-policy.html">Privacy Policy</Link>
      <Link href="/cookie-policy.html">Cookie Policy</Link>
    </div>
  );
}
