import { NavLink } from "react-router-dom";
import { LineItemByNameData } from "./LineItemByName";
import { Card, CardHeader, CardBody } from "@nextui-org/react";
import { Button } from "../atoms/Button";

export function LineItemByNameRow({
  lineItem,
}: {
  lineItem: LineItemByNameData;
}) {
  const date = new Date(lineItem.purchasedAt);
  const formatter = new Intl.DateTimeFormat("en-UK", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const formattedDate = formatter.format(date);

  return (
    <div className="my-4">
      <Card>
        <CardHeader className="text-sm justify-between">
          <div>
            {lineItem.vendorName} @ {formattedDate}
          </div>
        </CardHeader>
        <CardBody className="flex-row justify-between items-center">
          <div>
            {`${lineItem.quantity} X 
            ${(lineItem.unitPriceInCents / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "GBP",
            })} each`}
          </div>
          <div>
            <NavLink
              to={`/link-barcode-by-line-item-id/${encodeURIComponent(
                lineItem.lineItemId
              )}`}
            >
              <Button
                type={"button"}
                color={"default"}
                size={"sm"}
                text={"Add barcode"}
              />
            </NavLink>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
