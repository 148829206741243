import { useLoaderData } from "react-router-dom";
import { getApiHost } from "../getApiHost";
import { ReceiptRow } from "../ReceiptRow";

export type ReceiptLineItem = {
  lineItemId: string;
  lineItemName: string;
  quantity: number;
  totalInCents: number;
  barcode: string;
  lineItemDescriptiveName: string;
};

export type ReceiptLineItemResponse = {
  line_item_id: string;
  line_item_name: string;
  quantity: number;
  total_in_cents: number;
  barcode: string;
  line_item_descriptive_name: string;
};

export const loader =
  (token: string | undefined) =>
  async ({ params, request }: { params: any; request: any }) => {
    const response = await fetch(
      `${getApiHost()}/receipt/${params.receiptId}/line_items`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      const data: ReceiptLineItemResponse[] = await response.json();
      return {
        lineItems: data.map((lineItem) => ({
          lineItemId: lineItem.line_item_id,
          lineItemName: lineItem.line_item_name,
          quantity: lineItem.quantity,
          totalInCents: lineItem.total_in_cents,
          barcode: lineItem.barcode,
          lineItemDescriptiveName: lineItem.line_item_descriptive_name,
        })),
      };
    }
    return {
      lineItems: [],
    };
  };

export const action =
  (token: string | undefined) =>
  async ({ params, request }: { params: any; request: any }) => {
    const formData = await request.formData();
    const lineItemId = formData.get("lineItemId") as string;
    try {
      const response = await fetch(
        `${getApiHost()}/delete_barcode_by_line_item_id`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            lineItemId,
          }),
        }
      );
      if (response.status === 200) {
        console.log("Successfully removed barcode");
      } else {
        console.error("Failed to remove barcode");
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

export function Receipt() {
  const { lineItems } = useLoaderData() as {
    lineItems: ReceiptLineItem[];
  };

  return (
    <div>
      {lineItems.map((lineItem, index) => (
        <ReceiptRow key={index} lineItem={lineItem} />
      ))}
    </div>
  );
}
