import { useLoaderData } from "react-router-dom";
import { getApiHost } from "../getApiHost";
import { ReceiptsRow } from "../ReceiptsRow";
import { ampli } from "../ampli";
import { useEffect } from "react";

export type Receipt = {
  receiptId: string;
  vendorName: string;
  totalInCents: number;
  purchasedAt: Date;
  imageId: string;
};

type ReceiptResponse = {
  receipt_id: string;
  vendor_name: string;
  total_in_cents: number;
  purchased_at: string;
  image_id: string;
};

export const loader =
  (token: string | undefined) =>
  async ({ params, request }: { params: any; request: any }) => {
    const response = await fetch(`${getApiHost()}/receipts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      const data: ReceiptResponse[] = await response.json();
      return data.map((receipt) => ({
        receiptId: receipt.receipt_id,
        vendorName: receipt.vendor_name,
        totalInCents: receipt.total_in_cents,
        purchasedAt: new Date(receipt.purchased_at),
        imageId: receipt.image_id,
      }));
    }
    return [];
  };

export function Receipts() {
  useEffect(() => {
    ampli.receiptsPageViewed();
  }, []);

  const receipts = useLoaderData() as Receipt[];

  return (
    <div className="h-full">
      {!receipts.length ? (
        <div className="text-2xl p-5">
          Please upload your first receipt by clicking on the camera icon in the
          top-right.
        </div>
      ) : (
        <>
          <div className="text-center text-2xl p-4 font-medium">
            My Receipts
          </div>
          <div>
            {receipts.map((receipt) => (
              <ReceiptsRow key={receipt.receiptId} receipt={receipt} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
